






















import {
  Component,
  Inject, Provide,
  ProvideReactive,
  Vue,
} from 'vue-property-decorator';
import Page from '@/components/Page.vue';
import PageHeader1 from '@/components/PageHeader1.vue';
import { ApiFacade } from '@/services/ApiFacade';
import PageHeader2 from '@/components/PageHeader2.vue';
import { BreadcrumbsItem } from '@/types/BreadcrumbsItem';
import { createBreadCrumbsItems } from '@/views/courses/breadcrumbs';
import { Course } from '@/models/entities/Course';
import { AppStore } from '@/store/types/AppStore';

@Component({
  components: {
    PageHeader2,
    PageHeader1,
    Page,
  },
})
export default class CourseView extends Vue {
  @ProvideReactive()
  isLoading: boolean = true;

  @Inject() apiFacade!: ApiFacade;
  courseId: number = 0;

  @ProvideReactive()
  course: Course | null = null;

  @Inject()
  appStore!: AppStore;

  mounted() {
    this.courseId = parseInt(this.$route.params.courseId, 10);
    this.loadCourse();
  }

  @Provide()
  isCourseAdmin(): boolean {
    return Boolean(
      (this.appStore.getters.profile
        && this.course
        && this.course.isCourseAdmin(this.appStore.getters.profile))
      || this.appStore.getters.profile?.isSuperAdmin,
    );
  }

  @Provide()
  isCourseTeacher(): boolean {
    return Boolean(
      this.appStore.getters.profile
      && this.course
      && this.course.isCourseTeacher(this.appStore.getters.profile),
    );
  }

  private async loadCourse() {
    this.isLoading = true;
    try {
      this.course = await this.apiFacade.fetchCourse(this.courseId);
      Vue.set(this, 'course', this.course);
    } catch (err) {
      console.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  handleUpdateCourse() {
    this.loadCourse();
  }

  get breadcrumbs(): BreadcrumbsItem[] {
    return createBreadCrumbsItems();
  }
}
