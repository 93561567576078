import { BreadcrumbsItem } from '@/types/BreadcrumbsItem';

export function createBreadCrumbsItems(): BreadcrumbsItem[] {
  const items = [
    {
      text: 'Главная',
      to: { name: 'dashboard' },
    },
    {
      text: 'Курсы',
      exact: true,
      to: { name: 'courses' },
    },
  ];
  return items;
}
