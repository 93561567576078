<template functional>
  <h1 class="text-h5 mb-8 font-weight-light">
    <slot/>
  </h1>
</template>

<script>
export default {
  name: 'PageHeader2',
  functional: true,
};
</script>

<style scoped>

</style>
